import React, { Component } from 'react';
import { getCacheKeyForURL } from 'workbox-precaching';

class ClockOutConfirm extends Component {
  constructor(props){
    super(props);
    
    this.doneClick = this.doneClick.bind(this);
  }

  componentDidMount() {
    document.getElementById("doneBtn").disabled = true;
  }


  doneClick() {
    const tookLunch = document.querySelector('input[name="tookLunch"]:checked');
    const lunchFrom = document.getElementById('lunchFrom');
    const lunchFromTR = document.querySelector('#lunchFrom'); // v1.0.42
    const lunchTo = document.getElementById('lunchTo'); // v1.0.42
    const lunchToTR = document.querySelector('#lunchTo'); 
        this.props.breaksCH(
          this.props.act,
          this.props.pid,
          this.props.action, // v1.0.43c
          this.props.ein,// v1.0.43c
          this.props.pin,
          document.querySelector('input[name="tookBreaks"]:checked').value,
          document.querySelector('input[name="tookLunch"]:checked').value,
          document.getElementById("lunchFrom").value,
          document.getElementById("lunchTo").value // v1.0.42
        )
        console.log("Props @ done click clockout 33 ");console.log(this.props);
      };

  render(){
    
    console.log("state clockout 37 ");console.log(this.state);
    console.log("My Props clockout 38 ");console.log(this.props);
    return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <form >
            <div className="card" style={{ width: '28rem'}} id="breaks">
              <div className="card-header">Acknowledge your breaks
              </div>

              <div> &nbsp;</div>
              <div className="row" style={{ height: '4rem' }}><br/>
                <div className="col col-sm-8 px-0 mx-0">
                  <label className="form-check-label"><strong>I took my breaks</strong><br /><small>Tomé mis descansos</small></label>
                </div>

                <div className="col col-sm-2">
                  <input className="form-check-input" type="radio" name="tookBreaks" id="tookBreaksy" value="yes"/>&nbsp;&nbsp;&nbsp;
                  <label className="form-check-label" htmlFor="tookBreaksy">Yes</label>
                </div>

                <div className="col cols-sm-2">
                  <input className="form-check-input" type="radio" name="tookBreaks" id="tookBreaksn" value="no"/>&nbsp;&nbsp;&nbsp;
                  <label className="form-check-label" htmlFor="tookBreaksn">No</label>
                </div>
              </div>
              <div className="row" style={{ height: '4rem' }}>
                <div className="col col-sm-8 px-0 mx-0">
                  <label className="form-check-label col-sm-8"><strong>I took my lunch</strong><br/><small>tomé mi almuerzo</small> </label>
                </div>

                {/* v1.0.40
                <div className="col col-sm-2">
                  <input className="form-check-input" onClick={() => {document.getElementById("lunchFrom").disabled = false; document.getElementById("lunchFrom").required = true; console.log(document.getElementById("lunchFrom").length); if (document.getElementById("lunchFrom").length=0 || document.getElementById("lunchFrom").length==undefined){document.getElementById("doneBtn").disabled = true};}} type="radio" name="tookLunch" id="tookLunchy" value="yes"/>&nbsp;&nbsp;&nbsp;
                  <label className="form-check-label" htmlFor="tookLunchy">Yes</label>
                </div> 
                */}

                {/* v1.0.41 BEGIN*/}
                <div className="col col-sm-2">
                  <input className="form-check-input" 
                    onClick={() => {
                      document.getElementById("lunchFrom").disabled = false; 
                      document.getElementById("lunchFrom").required = true;
                      document.getElementById("lunchTo").disabled = false; 
                      document.getElementById("lunchTo").required = true; 
                      console.log('clockout82 lunchFrom ' + document.getElementById("lunchFrom").length); 
                      console.log('clockout83 lunchTo ' + document.getElementById("lunchTo").length);
                      if ( 
                        document.getElementById("lunchFrom").length==0 
                        || document.getElementById("lunchFrom").length==undefined
                        || document.getElementById("lunchTo").length==undefined
                        || document.getElementById("lunchTo").length==0 
                        )
                        {document.getElementById("doneBtn").disabled = true};
                      }
                    } 
                    type="radio" name="tookLunch" id="tookLunchy" value="yes"/>&nbsp;&nbsp;&nbsp;
                  <label className="form-check-label" htmlFor="tookLunchy">Yes</label>
                </div> 
                {/* v1.0.41 END*/}

                <div className="col col-sm-2">
                  <input className="form-check-input" onClick={() => {document.getElementById("lunchFrom").disabled = true; document.getElementById("lunchFrom").required = false; document.getElementById("doneBtn").disabled = false;}}  type="radio" name="tookLunch" id="tookLunchn" value="no"/>&nbsp;&nbsp;&nbsp;
                  <label className="form-check-label" htmlFor="tookLunchn">No</label>
                </div>
              </div>

              <div className="row" style={{ height: '4rem' }}>
                <div className="col col-sm-8 px-0 mx-0">
                  <label className="form-check-label col-sm-8" htmlFor="lunchFrom"><strong>I began Lunch at</strong> <br/><small>Empecé a almorzar en</small></label>
                </div>
                <div className="col col-sm-4 lunchFrom">
                  <input type="time" name="lunchFrom" className="form-time-input" id='lunchFrom' onChange={() => {document.getElementById("doneBtn").disabled = false; console.log('clockout 109 lunchFrom Value ' + document.getElementById("lunchFrom").value);}} disabled/>
                </div>
              </div>

              {/* v1.0.41 BEGIN */}
              <div className="row" style={{ height: '4rem' }}>
                <div className="col col-sm-8 px-0 mx-0">
                  <label className="form-check-label col-sm-8" htmlFor="lunchTo"><strong>I ended Lunch at</strong> <br/><small>terminó a almorzar en</small></label>
                </div>
                <div className="col col-sm-4 lunchTo">
                  <input type="time" name="lunchTo" className="form-time-input" id='lunchTo' onChange={() => {document.getElementById("doneBtn").disabled = false; console.log('clockout 119 lunchTo Value ' + document.getElementById("lunchTo").value);}} disabled/>
                </div>
              </div>
              {/* v1.0.41 End */}

              <div className="card-footer">
                <div id="Required">All Fields are Required</div>
                <button type="button" id="doneBtn" onClick={this.doneClick} className="btn btn-success"> Done </button>
                
              </div>
            </div>
            </form>
            </div>
            
    );
  }
}

export default ClockOutConfirm;