import React from "react";


function Debug(props) {
  return (
    <div align="center">
      <br></br>
        &copy; 2022 ADCO Drywall & Metal Framing Time Punch <code title={props.buildat}> [{props.build}]</code><br />
        {/* - ProjID - {props.pid}<br />
        - Act - {props.act}<br />
        - WorkerID - {props.wid}<br /> */}

    </div>
  );
}

export default Debug;
