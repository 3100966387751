import React, { Component } from "react";
// import ReactDOM from "react-dom";
import "./index.css";
import Pinpad from "./components/Pinpad";
import Debug from "./components/Debug";
import "./App.css";
import GPS from "./components/GPS";
import Headshot from "./components/Camera";
import Clock from "./components/Clock";
import Msg from "./components/Msg";
import ApiCall from "./components/ApiCall";
import Breaks from "./components/ClockOut";


class App extends Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    const pid = queryParams.get("pid");
    const ein = queryParams.get("ein"); // v1.0.43c
    const action= queryParams.get("action"); // v1.0.43c
    
    
    this.state = {
      workers: [],
      showPinpad: true,
      pin: "",
      showDebug: true,
      showCamera: false,
      photo: "",
      showGPS: false,
      lat: "",
      lng: "",
      showClock: true,
      showMsg: true,
      msg: "Enter Your 4 Digit Pin",
      wid: "",
      pid: pid,
      ein: ein, // v1.0.43c
      action: action, // v1.0.43c
      act: "",
      refreshBTN: "Update App",
      showBreaks: false,
      tookLunch: "",
      lunchFrom: "",
      lunchTo: "",
      tookBreaks: "",
      time: new Date().toLocaleTimeString(),
      build: "1.0.43e",
      buildat: "[43e]3/13/2024 correct variable passing between components,[43c]2/21/2024,[43]1/4/2024,[42]12/3/2022",
      changelog: "43d:Add debug to troubleshoot|43c:Fix ein variable lost error|43:Add variable clock in time allowed based on projects Earliest clock-in value|42:Re-Add Lunch To|40:Prevent pre 6:50am clock-ins|39:Add Cancel button to bottom of page after pin page|38:gone production, dropping b|37:prevent clock-out form submission unless theres a lunch from time if lunch=yes. |36:add update now link to pin page to force refresh of app."
    };
    console.log("app.js 53");console.log(this.state);
    // console.log('this.state.ein ' + this.state.ein);
    this.workerClickHandler = this.workerClickHandler.bind(this);
    this.clockinClickHandler = this.clockinClickHandler.bind(this);
    this.clockoutClickHandler = this.clockoutClickHandler.bind(this);
    this.pinChangeHandler = this.pinChangeHandler.bind(this);
    this.breaksClickHandler = this.breaksClickHandler.bind(this);

    console.log("app.js 61");console.log(this.state);

    
  }


  async handleRefreshClick () {
    // navigator.serviceWorker.getRegistrations().then((registrations) => {
    //   registrations.forEach((registration) => {
    //     registration.unregister();
    //   });
    // });

    caches.keys().then((keyList) => {
      return Promise.all(
        keyList.map((key) => {
          return caches.delete(key);
        })
      );
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);

  }



  workerClickHandler(wid, act, lat, lng) {
    // const queryParams = new URLSearchParams(window.location.search);
    // const pid = queryParams.get('pid');
    console.log("app92 workerCH this.state ");console.log(this.state);
    this.setState({
      showCamera: true,
      showPinpad: false,
      showGPS: true,
      showClock: true,
      showWorkers: false,
      showMsg: true,
      showBreaks: false,
      msg: "Take a photo to complete your time punch",
      wid: wid,
      // pid: pid,
      act: act,
      lat: lat,
      lng: lng,
    });
    // console.log("workerclick WID:" + this.state.wid + "act: " + this.state.act);
    console.log("app109 exit this.state ");console.log(this.state);
  }



  clockinClickHandler(pin) {
    // const queryParams = new URLSearchParams(window.location.search);
    // const pid = queryParams.get('pid');
    // this.interval = setInterval(() => this.setState({ time: new Date().toLocaleTimeString() }), 1000);
    console.log("app118 clockinCH this.state ");console.log(this.state);
    var time = new Date();
    time = time.toLocaleTimeString('en-US', { hour12: false,});
    // console.log(this.state);

    if (time >= this.state.ein) {
      document.getElementById('Msg').innerHTML='Loading...';
    this.setState(
      {
        act: "Clock-In",
        showCamera: false,
        showPinpad: false,
        showGPS: false,
        showClock: false,
        showWorkers: true,
        showMsg: true,
        msg: "Loading...",
        pin: pin,
        refreshBTN: "Cancel"
      }
      // ,
      // () =>
      // console.log('132-state: ' & this.state)
    );
  } else {

    document.getElementById('Msg').innerHTML='<span style="color:red; font-weight:bold; font-size:1.5em">Regístrese después de las ' + this.state.ein + ' AM<br><small>Please clock-in after ' + this.state.ein + ' AM</small></span>';

  }
  console.log("clockinch exit this.state ");console.log(this.state);
  }



  clockoutClickHandler(pin) {
    // const queryParams = new URLSearchParams(window.location.search);
    // const pid = queryParams.get('pid');
    console.log("app155 clockoutCH this.state " );console.log( this.state);
    this.setState({
      showCamera: false,
      showPinpad: false,
      showGPS: false,
      showClock: false,
      showWorkers: false,
      showMsg: true,
      showBreaks: true,
      // pid: pid,
      msg: "",
      pin: pin,
      act: "Breaks",
      refreshBTN: "Cancel"
    });
    // console.log(this.state);
    // console.log("out pid " + this.state.pid);
    // console.log(
    //   "outclick this. PIN:" + this.state.pin + "ACT: " + this.state.act
    // );
    console.log("app175 clockoutCH exit this.state " );console.log( this.state);
  }



  breaksClickHandler(act, pid, action, ein, pin, tookBreaks, tookLunch, lunchFrom, lunchTo) {
    // console.log("BREAKS ACT " + act);
    // console.log("pid " + pid);
    // console.log("pin " + pin);
    // console.log("tookBreaks " + tookBreaks);
    // console.log("tookLunch " + tookLunch);
    // console.log("lunchFrom " + lunchFrom);
    // const queryParams = new URLSearchParams(window.location.search);
    // const pid = queryParams.get('pid');
    console.log("app 189 breaksCH this.state " );console.log( this.state);
    this.setState({
      showCamera: false,
      showPinpad: false,
      showGPS: false,
      showClock: false,
      showWorkers: true,
      showMsg: true,
      showBreaks: false,
      tookLunch: tookLunch,
      tookBreaks: tookBreaks,
      lunchFrom: lunchFrom,
      lunchTo: lunchTo,
      msg: "Loading...",
      act: "Clock-Out"
      
    },
    () => {console.log('app 206 this.state ' );console.log( this.state);}
    );

    // console.log("THIS.BREAKS ACT " + this.state.act);
    // console.log("pid " + this.state.pid);
    // console.log("pin " + this.state.pin);
    console.log("app 212 breaksCH exit this.state " );console.log( this.state);
  }


  pinChangeHandler(pin) {
    this.setState({
      pin: pin,
    });
  }



  
  render() {
    return (
      <div className="container">
        <div
          className="col-sm-12 text-center center"
          style={{ height: "131", width: "auto" }}
          id="Logo"
        >
          <img className="mx-auto d-block" src="./images/logo.png" alt="logo"/>
        </div>
        <div className="App">
          <div className="col-sm-12 text-center" id="Clock">
            {this.state.showClock ? <Clock /> : null}
          </div>
          <div className="col-sm-12 text-center" id="Msg">
            {this.state.showMsg ? <Msg msg={this.state.msg} /> : null}
          </div>
          <div className="row">
            <div className="cols-sm-3"> </div>
            <div className="cols-sm-6" id="Headshot">
            {this.state.showCamera ? (
              <Headshot
                wid={this.state.wid}
                pid={this.state.pid}
                action={this.state.action} // v1.0.43c
                ein={this.state.ein} // v1.0.43c
                act={this.state.act}
                lat={this.state.lat}
                lng={this.state.lng}
                tookBreaks={this.state.tookBreaks}
                tookLunch={this.state.tookLunch}
                lunchFrom={this.state.lunchFrom}
                lunchTo={this.state.lunchTo}
                build={this.state.build}
                showPinpad="false"
              />
            ) : null}
            </div>
            <div className="cols-sm-3"> </div>
          </div>
          <div className="col-sm-12 text-center" id="Main">
            {this.state.showPinpad ? (
              <Pinpad
                clockinCH={this.clockinClickHandler}
                clockoutCH={this.clockoutClickHandler}
                workerCH={this.workerClickHandler}
                build={this.state.build}
                showPinpad={this.state.showPinpad}
              />
            ) : null}
          </div>
          <div className="col-sm-12 text-center" id="Submain">
            {this.state.showBreaks ? (
              <Breaks
                pid={this.state.pid}
                action={this.state.action} // v1.0.43c
                ein={this.state.ein} // v1.0.43c
                wid={this.state.wid}
                pin={this.state.pin}
                breaksCH={this.breaksClickHandler}
                act={this.state.act}
                build={this.state.build}
                showPinpad="false"
              />
            ) : null}
            {this.state.showWorkers ? (
              <ApiCall
                pid={this.state.pid}
                action={this.state.action} // v1.0.43c
                ein={this.state.ein} // v1.0.43c
                wid={this.state.wid}
                pin={this.state.pin}
                projID={this.state.projID}
                workerCH={this.workerClickHandler}
                act={this.state.act}
                build={this.state.build}
                showPinpad="false"
              />
            ) : null}
          </div>
          <div className="col-sm-12 text-center" id="GeoLoc">
            {this.state.showGPS ? <GPS /> : null}
          </div>
        </div>
        <br />
        <br />
        <div id="refresh" align="center">
          <button id="refresh" onClick={this.handleRefreshClick}>{this.state.refreshBTN}</button>

        </div>
        
        <div id="Debug">
          {this.state.showDebug ? (
            <Debug
              pid={this.state.pid}
              action={this.state.action} // v1.0.43c
              ein={this.state.ein} // v1.0.43c
              wid={this.state.wid}
              act={this.state.act}
              build={this.state.build}
              buildat={this.state.buildat}
              tookBreaks={this.state.tookBreaks}
              tookLunch={this.state.tookLunch}
              lunchFrom={this.state.lunchFrom}
              lunchTo={this.state.lunchTo}
            />
          ) : null}
        </div>
        
      </div>
    );
  }
}

export default App;
