import Axios from 'axios'
import React, { useState, useEffect }  from "react";
import ReactDOM from "react-dom";
import Msg from './Msg';
// import { render } from 'react-dom';


function ApiResponse(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [workers, setWorkers] = useState([]);
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [status, setStatus] = useState(null);
    // console.log(props);


    useEffect(() => {
      fetchWorkers();
    }, [])

      const fetchWorkers=async()=>{
      const response=await Axios(`https://timeclock.adcodrywall.com/rest.cfm?function=empLookup&sl4=${props.pin}`);
      setWorkers(response.data)
      // console.log(workers);  
      // console.log(window.navigator.serviceWorker);
      setIsLoaded(true);
      if (!navigator.geolocation) {
        setStatus('Geolocation is not supported by your browser');
      } else {
        setStatus('Locating...');
        navigator.geolocation.getCurrentPosition((position) => {
          setStatus(null);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        }, () => {
          setStatus('Unable to retrieve your location');
        });
      }
    }

  // v1.0.43c - line 49, 62,  action and ein forwarding
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (isLoaded) {
      if (workers === undefined || workers.length == 0) {
      ReactDOM.render(<Msg msg='No Records Found!'/>, document.getElementById("Msg"));
      return <div>
        <a href={"/?pid=" + props.pid + "&action=" + props.action + "&ein=" + props.ein}>
        <button className="PINbuttonLg clockIn">Try Again</button>
        </a>
      </div>;
        } else {
          ReactDOM.render(<Msg msg='Tap your name to continue'/>, document.getElementById("Msg"));
          return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <div className="card" style={{ width: '18rem' }} id="workerlist">
              <div className="card-header">
                
              </div>
              <ul className="list-group list-group-flush">
              {workers.map(worker => (
                <li className="list-group-item" key={worker.wid} onClick={(wid) => props.workerCH(worker.wid,props.act,lat,lng,props.pid,props.action,props.ein,props.tookLunch,props.lunchFrom,props.lunchTo,props.tookBreaks,props.build)}>{worker.employee_name} ({worker.employee_code}) </li>
                
              ))}
              </ul>
            </div>
            </div>}
    } else {
      return (null);
    }
  }

  export default ApiResponse;
  
