import React, { useState } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import axios from 'axios'
import ReactDOM from "react-dom";
import Msg from './Msg';
// import ImagePreview from './imagePreview.js';

function Headshot(props) {
  const [dataUri, setDataUri] = useState('');
  const [isRemoveCamera, setIsRemoveCamera] = useState(false);
  // console.log('cam12 props: ' );console.log( props);
  // console.log('cam13 this.state ' );console.log( this.state);
  // console.log('cam13 act: ' + props.act);
  // console.log('cam14 lunchFrom: ' + props.lunchFrom);
  // console.log('cam15 lunchTo: ' + props.lunchTo);
  // console.log('cam16 tookLunch: ' + props.tookLunch);
  // console.log('cam17 tookBreaks: ' + props.tookBreaks);
  // umount camera after 10 seconds
  // setTimeout(() => {
  //   setIsRemoveCamera(true);
  // }, 2000);
  ReactDOM.render(<Msg msg='Take a picture'/>, document.getElementById("Msg"));
  
  function handleTakePhoto (dataUri) {
    // Do stuff with the photo...
    // console.log('cam26 handletakePhoto');
    ReactDOM.render(<Msg msg='Taking Photo...'/>, document.getElementById("Msg"));
  }


  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    setDataUri(dataUri);
    ReactDOM.render(<Msg msg='Saving Punch...'/>, document.getElementById("Msg"));
    console.log('cam 35 handleTakePhotoAnimationDone');
    
    var today = new Date();
    var nowdate = today.toLocaleDateString('en-US');
    var nowtime = today.toLocaleTimeString('en-US');
    const isFullscreen = false;
    var form = new FormData();
   
    const imgName = 'myIMG.png';
    form.append('img', dataUri);
    form.append('workerID', props.wid);
    form.append('date', nowdate);
    form.append('punchTime', nowtime);
    form.append('lunchFrom', props.lunchFrom);
    form.append('lunchTo', props.lunchTo);
    form.append('tookLunch', props.tookLunch);
    form.append('tookBreaks', props.tookBreaks);
    form.append('gpslat', props.lat);
    form.append('gpslong', props.lng);
    form.append('act', props.act);
    form.append('pid', props.pid); // v1.0.43c
    form.append('action', props.action); // v1.0.43c
    form.append('ein', props.ein); // v1.0.43c
    form.append('build', props.build);
    form.append('function', 'addPunch');
    // console.log(form);
    
    axios({
      method: "post",
      url: "https://timeclock.adcodrywall.com/rest.cfm",
      data: form,
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(function (response) {
        //handle success
        console.log('cam71 rest call successful ');
        console.log( response);
        ReactDOM.render(<Msg msg='Time Punch Complete!'/>, document.getElementById("Msg"));
       { window.location.reload(false);}
        
      })
      .catch(function (response) {
        //handle error
        console.log('cam 79 fail ' );console.log( response);
        ReactDOM.render(<Msg msg='Time Punch Failed.. Er82cam'/>, document.getElementById("Msg"));
    });
    

    console.log('cam84 Axios post complete')

    setIsRemoveCamera(true);
    
    return (<div>
        <img src={dataUri} alt="selfie"/>
         
      </div>
    );
    
  }

  function handleCameraError (error) {
    console.log('cam 97 handleCameraError' );console.log( error);
  }

  function handleCameraStart (stream) {
    console.log('cam 101 handleCameraStart');
  }

  function handleCameraStop () {
    console.log('cam 105 handleCameraStop');

  }
  return isRemoveCamera ? null : (
    <div>
    <Camera
      onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
      onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
      onCameraError = { (error) => { handleCameraError(error); } }
      idealFacingMode = {FACING_MODES.USER}
      idealResolution = {{width: 160, height: 120}}
      imageType = {IMAGE_TYPES.JPG}
      imageCompression = {0.6}
      isMaxResolution = {false}
      isImageMirror = {false}
      isSilentMode = {true}
      isDisplayStartCameraError = {true}
      isFullscreen = {false}
      sizeFactor = {1}
      onCameraStart = { (stream) => { handleCameraStart(stream); } }
      onCameraStop = { () => { handleCameraStop(); } }
      
    />
    </div>
  );
}

export default Headshot;