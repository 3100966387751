import React, { useState } from "react";

export default function Pinpad(props) {
  
    const [pin, setPin] = useState("");
    function pinChangeHandler() {
        setPin({pin});
    }



    return (
      <div className="center">
       
        <div className="btn-group-vertical shadow mt-2 border" role="group">
          <div className="btn-group">
  
            <input
              type="text"
              className="pinbox"
              value={pin}
              name="pincode"
              maxLength={4}
              onChange={pinChangeHandler}
            />
          </div>
  

          <div className="btn-group center">
            <button
              className="PINbutton"
              onClick={() => setPin((pin) => `${pin.substring(0,3)}1`)}
            >
              1
            </button>
            <button
              className="PINbutton"
              onClick={() => setPin((pin) => `${pin.substring(0,3)}2`)}
            >
              2
            </button>
  
            <button
              className="PINbutton"
              onClick={() => setPin((pin) => `${pin.substring(0,3)}3`)}
            >
              3
            </button>
          </div>
  

          <div className="btn-group center">
            <button
              className="PINbutton"
              onClick={() => setPin((pin) => `${pin.substring(0,3)}4`)}
            >
              4
            </button>
  
            <button
              className="PINbutton"
              onClick={() => setPin((pin) => `${pin.substring(0,3)}5`)}
            >
              5
            </button>
  
            <button
              className="PINbutton"
              onClick={() => setPin((pin) => `${pin.substring(0,3)}6`)}
            >
              6
            </button>
          </div>


          <div className="btn-group center">
            <button
              className="PINbutton"
              onClick={() => setPin((pin) => `${pin.substring(0,3)}7`)}
            >
              7
            </button>
  
            <button
              className="PINbutton"
              onClick={() => setPin((pin) => `${pin.substring(0,3)}8`)}
            >
              8
            </button>
  
            <button
              className="PINbutton"
              onClick={() => setPin((pin) => `${pin.substring(0,3)}9`)}
            >
              9
            </button>
          </div>
  

          <div className="btn-group center">
            <button
              className="PINbutton clear"
              onClick={() => setPin("")}
            >
              CLEAR
            </button>
  
            <button
              className="PINbutton"
              onClick={() => setPin((pin) => `${pin.substring(0,3)}0`)}
            >
              0
            </button>
  
            <button 
              className="PINbutton del"
              onClick={() => setPin(pin.slice(0, -1))}
            >
              DEL
            </button>
          </div>


          <div className="btn-group center">
            <button 
              className="PINbuttonLg clockIn" 
              id="clockIn" 
              onClick={() => props.clockinCH(pin,props.act,props.earlyclockin)}
              // onClick={(wid) => props.click(worker.wid,props.act,lat,lng)}
            >
              Clock In
            </button>

  
            <button 
              className="PINbuttonLg clockOut" 
              id="clockOut" 
              onClick={() => props.clockoutCH(pin,props.act)}
            >
              Clock Out
            </button>
          </div>

        </div>
        
       
      </div>
    );
  
 
  }
  
 